$primary-color: #00ab00;
$background-color-first: #61d261;
$background-color-second: #eee;
$background-color-light: #fafafa;

$title-color: #303538;
$title2-color: #303538;
$text-color: #303538;

$background-exercise-color: #61d261;
$background-warmup-color: #f6cf49;
$background-rest-color: #47a5c4;
$background-big-rest-color: #8e8e8e;

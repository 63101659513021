.workout-preview {
  position: absolute;
  display: flex;
  align-content: center;
  width: 100%;
  top: 0;
  left: 0;
  height: calc(100vh - 250px);

  & .title-series {
    position:absolute;
    z-index: 10;
    width: 100%;
    top: 20px;
    text-align: center;
    font-size: 1.7rem;
    font-weight: 500;

    @media screen and (max-width: 550px) {
      font-size: 1.2rem;
    }

    @media screen and (max-width: 400px) {
      font-size: 1rem;
    }
  }

  .cycle-container {
    position: relative;
    padding-top: 10px;
    width: 95%;
    max-width: 70vh;
    margin: auto;

    & svg {
    }
  }
}

@import '../../../resources/styles/layout/variables';

.custom-textfield {
  .BorderedTextField-input-6 {
    background: white;
  }
  .BorderedTextField-root-9 {
    color: $text-color;
  }
}

@import '../../../resources/styles/layout/variables';

.header {
  position: relative;
  display: flex;
  z-index: 10;
  align-items: center;
  margin: auto;
  background-color: $background-color-first;
  color: white;
  padding: 10px 30px;

  & img.logo {
    width: 100px;
    height: auto;
  }

  & .header-title {
    padding-left: 25px;
    font-size: 4rem;
    color: white;
  }

  @media screen and (max-width: 1024px) {
    & {
      & .header-title {
        padding-left: 25px;
        font-size: 3rem;
      }
    }
  }
  @media screen and (max-width: 600px) {
    & {
      & img.logo {
        width: 64px;
        height: auto;
      }

      & .header-title {
        padding-left: 20px;
        font-size: 2rem;
      }
    }
  }
  @media screen and (max-width: 400px) {
    & {
      & img.logo {
        width: 48px;
        height: auto;
      }

      & .header-title {
        padding-left: 10px;
        font-size: 1.5rem;
      }
    }
  }

  &::after {
    content: ' ';
    bottom: -35px;
    left: 0;
    position: absolute;
    border-left: calc(100vw - 20px) solid $background-color-first;
    border-bottom: 35px solid transparent;
    color: white;
  }

  @media screen and (max-width: 500px) {
    &::after {
      bottom: -20px;
      border-bottom: 20px solid transparent;
    }
  }

  &.thin {
    height: 55px;
    padding: 5px 25px;

    &::after {
      content: none;
    }

    & img.logo {
      width: 32px;
      height: auto;
    }

    & .header-title {
      padding-left: 25px;
      font-size: 1.7rem;
    }
  }
}

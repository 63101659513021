@import '../../../../resources/styles/layout/variables';

.creation-seance-form {
  padding-bottom: 128px;

  & .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & h3 {
      padding-right: 24px;
    }
    padding-bottom: 10px;
  }

  & .series-fields {
    & .series-field {
      width: 30%;
      padding-right: 3%;
      margin-top: 15px;
      display: inline-block;
      justify-content: space-between;

      & > div {
        width: 100%;
      }

      @media screen and (max-width: 1050px) {
        width: 40%;
      }
      @media screen and (max-width: 850px) {
        width: 100%;
        padding: 0;
      }
    }
  }

  & .exercices-container {
    padding-bottom: 40px;
    width: 100%;
    clear: both;
  }

  ul.exercises-list {
    list-style: none;
    padding: 0;

    & .exercises-item:first-child {
      margin-top:10px;
    }
    & .exercises-item {
      position: relative;
      margin-top: 30px;

      & .order-exercise-container {
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 70px;
        top: 0;
        right: 50px;

        & button {
          cursor: pointer;
          background-color: white;
          border: 2px solid #e5e5e5;
          color: $primary-color;
          font-size: 1.3rem;
          font-weight: 600;
          text-align: center;
          &:focus  {
            outline: none;
          }
          &:disabled {
            cursor: default;
            color: #e5e5e5;
          }
        }
      }

      & .delete-exercise-container {
        position: absolute;
        top: 0;
        right: 0;

        & button {
          cursor: pointer;
          border: 2px solid #c81e1e;
          color: white;
          border-radius: 50px;
          background: #c81e1e;
          font-size: 1.3rem;
          font-weight: 600;
          text-align: center;
        }
        button:focus  {
          outline: none;
          border: 2px solid #000000;
        }
      }

      & fieldset {
        background: $background-color-light;

        legend {
          font-size: 1.1rem;
          font-weight: 500;
        }
      }

      & .exercise-fields {
        & .exercise-field {
          width: 30%;
          padding: 0 1%;
          margin-top: 15px;
          display: inline-block;

          & > div {
            width: 100%;
          }

          @media screen and (max-width: 1090px) {
            width: 40%;
          }
          @media screen and (max-width: 840px) {
            width: 100%;
            padding: 0;
          }
        }
      }
    }
  }

  & .add-exercise-container {
    display: flex;
    justify-content: center;
    padding-top: 30px;

    & button {
      color: $text-color;
      border: none;
      outline: none;
      & i {
        color: #606060;
      }
    }
  }

  & .action-button {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;

    & > * {
      margin-top: 10px;
    }

    & button.square-btn.add-to-favourite {
      border: none;
      color: $text-color;
      outline: none;
      & i {
        color: #606060;
      }
    }
    & button.square-btn.add-to-favourite:disabled {
      color: #bababa;
      border-color: #bababa;
      & i {
        color: #bababa;
      }
    }
  }

  @media screen and (max-width: 800px) {
    & .action-button {
      & button.square-btn {
        font-size: 1rem;
        padding: 10px 15px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    & .action-button {
      align-items: initial;
      width: 100%;
    }
  }
}

@import '../../../resources/styles/layout/variables';

.navigation-bottom-bar {
  position: fixed;
  z-index: 10;
  left: 0;
  bottom: 0;
  width: 100%;
  max-witdh: 1024px;
  height: 56px;
  border-top: 2px solid $background-color-second;
  background-color: white;
  padding: 5px 5%;

  & .menu-item-list {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    margin: auto;

    & div.menu-item {
      display: flex;
      align-content: space-between;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 45px;
      padding: 5px;

      & a {
        color: $text-color;
        font-size: 1rem;
        font-weight: 400;
      }

      & button {
        cursor: pointer;
        background: none;
        border: none;
        color: $text-color;
        font-size: 1rem;
        font-weight: 400;

        &:focus {
          background: none;
          border: none;
          outline: none;
        }
      }

      & i {
        font-size: 1.5rem;
        display: block;
        padding-bottom: 2px;
      }

      @media screen and (max-width: 500px) {
        & a {
          font-size: 0.8rem;
        }
        & button {
          font-size: 0.8rem;
          & i {
            font-size: 1rem;
          }
        }
        & i {
          font-size: 1rem;
        }
      }
    }

    & .menu-item-menu {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

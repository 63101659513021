@import '../../../resources/styles/layout/variables';

.share-strava-workout {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  width: 100%;
  color: white;
  background-color: $background-color-first;
  overflow: hidden;
}

@import '../../../../resources/styles/layout/variables';

.favourites-list {
  list-style: none;
  padding-left: 0;

  & li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 1px solid #bababa;
    height: 60px;
    margin: 15px 0;
    & a {
      padding: 20px;
      width: 100%;
      font-size: 1rem;
      color: $text-color;
    }
  }

  & .favourites-list-item-button {
    cursor: pointer;
    padding: 20px;
    color: $text-color;
    background: none;
    border: none;
    outline: none;
    font-size: 1.5rem;
  }
}

.empty-seance-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
  & p {
    text-align: center;
  }
}

@import '../../../resources/styles/layout/variables';

.custom-select {
  & .BorderSelect-label-13 {
    color: $text-color;
    font-size: 1.1rem;
    letter-spacing: 0.00938em;
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;

  }

  & .BorderSelect-select-14 {
    border-color: #b0bec5;
    padding: 10px;
    margin-top: 5px;
    line-height: 1rem;
  }
}

@import '../../../resources/styles/layout/variables';

.homepage {
  height:100%;
  min-height: 100vh;
  padding-bottom: 64px;

  /*----------------- SECTIONS */
  & .section {
    height: 100%;
    position: relative;
    background: white;
    padding: 45px 45px;
  }

  & .section-creation-seance::before,
  & .section-seances::before {
    content: ' ';
    top: 0;
    left: 0;
    position: absolute;
    border-left: calc(100vw - 20px) solid $background-color-first;
    border-bottom: 35px solid transparent;
    color: white;
  }

  /*----------------- INTRODUCTION */
  & .section-introduction {
    display: flex;
    vertical-align: center;
    background: white;
    color: $text-color;
    padding: 30px 45px 0 45px;

    & .introduction-illustration {
      display: flex;
      align-items: center;
      padding: 40px;
      width: 40%;
      & > img {
        width: 100%;
        max-width: 400px;
        margin: auto;
      }
    }
    & .introduction {
      width: 60%;
    }
  }

  @media screen and (max-width: 1280px) {
    & .section-introduction {
      display: block;
      & .introduction-illustration {
        width: 100%;
        padding-left: 0;
        padding-top: 0;
      }

      & .introduction {
        width: 100%;
      }
    }
  }

  /*----------------- CREATION SEANCE */
  & .section-creation-seance {
    display: flex;
    vertical-align: center;
    background: $background-color-second;
    color: $text-color;

    &::before {
      border-left: calc(100vw - 20px) solid white;
    }

    & .create-seance-illustration {
      display: flex;
      align-items: center;
      padding-right: 100px;
      width: 30%;

      & > img {
        width: 100%;
        height: auto;
        max-width: 70px;
        margin: auto;
      }
    }
    & .create-seance {
      width: 60%;
    }

    & .action-button {
      display: flex;
      justify-content: flex-end;
      margin-top: 35px;
    }

    @media screen and (max-width: 900px) {
      & .create-seance-illustration {
        display: none;
      }
      & .create-seance {
        width: 100%;
      }

      & .action-button {
        justify-content: center;
      }
    }
  }

  /*----------------- EXEMPLES SEANCES */
  & .section-seances {
    background: white;
    color: $text-color;
    text-align: center;

    &::before {
      left: auto;
      right: 0;
      border-left: none;
      border-right: calc(100vw - 20px) solid $background-color-second;
      border-bottom: 35px solid transparent;
    }

    & .introduction {
      max-width: 1024px;
      margin: auto;
      padding-bottom: 30px;
      & p {
        text-align: center;
      }
    }

    & .list-cards {
      display: flex;
      justify-content: space-between;
      max-width: 1440px;
      margin: auto;
      @media screen and (max-width: 1024px) {
        display: block;
      }
      & .card {
        position: relative;
        padding: 30px 40px 100px 40px;
        margin: 0 10px;
        text-align: center;
        width: 50%;
        min-height: 200px;
        background: $background-color-second;

        @media screen and (max-width: 1024px) {
          width: 100%;
          margin: 0 0 25px 0;
        }

        & p {
          text-align: justify;
        }

        & .action-container {
          position: absolute;
          bottom: 40px;
          left: 0;
          width: 100%;
        }
        & a.btn-seance {
          cursor: pointer;
          background: $background-color-first;
          border-radius: 25px;
          font-size: 1.2rem;
          font-weight: bold;
          color: white;
          padding: 15px 25px;
          margin: auto;

          & > i {
            padding-right: 10px;
          }

          @media screen and (max-width: 700px) {
            font-size: 1rem;
          }
        }
      }
    }

    & .list-seances {
      display: flex;
      flex-direction: column;
      margin: auto;
      height: 100%;
      width: 90%;
      max-width: 1024px;
      & > * {
        display: block;
        margin-top: 7%;
      }
      @media screen and (max-width: 700px) {
        width: 90%;
      }
    }
  }
}

.favourite-form-fields-container {
}

.favourite-form-submit-container {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;

  & button {
    margin: 0px 5px;
  }

  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;

    & button {
      margin: 5px 0px;
    }
  }
}

@import '../../../../resources/styles/layout/variables';

.timeline {
  position: absolute;
  display: flex;
  width: 100%;
  height: 40vh;
  padding-top: 1vh;
  align-items: center;
  justify-content: center;

  & .exercise {
    position: absolute;
    height: 100%;
    width: 100%;

    & .exercise-title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 10%;
      width: 100%;
      padding: 10px;
      font-size: 2rem;
      font-weight: 500;
      clear: both;

      &.full {
        height: 100%;
      }
    }

    & .exercise-illustration {
      position: relative;
      display: flex;
      justify-content: center;
      height: 90%;
      width: 100%;
      & > img {
        height: 100%;
        width: auto;
      }
    }
  }
}

.next-exercise-preview {
  position: absolute;
  right: 20px;
  top: 25%;
  width: 150px;
  height: 150px;
  border-radius: 75px;
  border: solid 5px white;
  background-color: white;
  color: $background-big-rest-color;

  @keyframes changeposition {
    from {
      right: 30px;
    }

    to {
      right: 15px;
    }
  }
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-name: changeposition;
  animation-iteration-count: infinite;
  animation-direction: alternate;

  & .exercise-illustration {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 15px;
    height: 80%;
    width: 100%;
    & > img {
      height: 100%;
      width: auto;
      opacity: 0.75;
    }
  }
  .next-exercise-preview-arrow {
    position: absolute;
    right: -20px;
    top: calc(50% - 20px);
    content: '';
    width: 0;
    height: 0;
    border-left: solid 20px white;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }

  .next-exercise-title {
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    font-weight: bold;
  }

  @media screen and (max-width: 700px) {
    position: absolute;
    right: 20px;
    top: 50%;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border: solid 5px white;

    .next-exercise-preview-arrow {
      right: -15px;
      top: calc(50% - 15px);
      border-left: solid 15px white;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
    }
  }
}



@media screen and (max-width: 700px) {
  .timeline {
    height: 30vh;

    & .exercise {
      height: auto;
      max-height: 90%;

      & .exercise-title {
        font-size: 1.7rem;
      }
      & .exercise-illustration {
        & > img {
          max-width: 100%;
          max-height: 30vh;
          height: auto;
        }
      }
    }
  }
}

.player {
  position: absolute;
  height: 150px;
  display: flex;
  justify-content: center;
  bottom: 50px;
  width:100%;

  & .player-controls {
    display: flex;
    width: 100%;
    padding: 0 10px;
    max-width: 400px;
    align-items: center;
    justify-content: space-between;

    & div {
      position: relative;
      display: flex;
      justify-content: center;
      width: 7rem;
    }
    & i {
      cursor: pointer;
      font-size: 7rem;
      color: white;

      @media (max-height: 800px),
      screen and (max-width: 800px) {
        font-size: 5rem;
      }

      @media (max-height: 500px),
      screen and (max-width: 500px) {
        font-size: 4rem;
      }

      &.small {
        font-size: 5rem;
        @media (max-height: 800px),
        screen and (max-width: 800px) {
          font-size: 4rem;
        }
        @media (max-height: 500px),
        screen and (max-width: 500px) {
          font-size: 3rem;
        }
      }
    }
  }
}

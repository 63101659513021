@import '../../../../resources/styles/layout/variables';

.workout-end {
  background-color: white;
  position: relative;
  height: 100vh;
  width: 100%;
  color: white;
  overflow: hidden;

  & .banner-title {
    & .retour-button {
      position: absolute;
      z-index: 100;
      left: 30px;
      color: white;
      font-size: 2.5rem;
      border: none;
      background: transparent;
      cursor: pointer;
      outline: none;
    }

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10vh;
    font-size: 2.5rem;
    font-weight: 700;
    background: $background-color-first;;
    &::after {
      content: ' ';
      bottom: -35px;
      left: 0;
      position: absolute;
      border-left: calc(100vw - 20px) solid $background-color-first;
      border-bottom: 35px solid transparent;
      color: white;
    }

    @media screen and (max-width: 500px) {
      &::after {
        bottom: -20px;
        border-bottom: 20px solid transparent;
      }
    }
  }
  .workout-end-body {
    color: $text-color;
    width: 100%;
    max-width: 1024px;
    padding: 120px 32px 20px 32px;
    margin: auto;
    text-align: center;
    background-color: white;
    @media screen and (max-height: 800px) {
      padding: 64px 32px 20px 32px;
    }
    & .description {
      padding-top: 20px;
      & p {
        font-size: 1.1rem;
        text-align: center;
      }
    }
    & .illustration-win {
      max-width: 500px;
      margin: auto;
      @media (max-height: 700px),
      screen and (max-width: 700px) {
        max-width: 300px;
      }
    }
  }
}

.strava-share-btn {
  cursor: pointer;
  border: 2px solid #FC4C02;
  padding: 16px;
  width: fit-content;
  max-width: 100%;
  margin: 50px auto auto;
  @media screen and (max-height: 700px) {
    margin: 18px auto auto;
  }
  background: white;
  outline: none;
  color: #FC4C02;
  font-size: 1.1rem;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & img {
    margin-right: 16px;
  }
  &:hover {
    border: 2px solid #bf3a02;
  }
}

.time-attack-container {
  position: absolute;
  top: 40vh;
  height: 30vh;
  width: 100%;
  font-weight: 700;
  user-select:none;

  & .timer,
  & .repetition {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .repetition {
    font-size: 8rem;

    @media screen and (max-height: 700px),
    screen and (max-width: 700px) {
      font-size: 6rem;
    }
    @media (max-height: 600px),
    screen and (max-width: 600px) {
      padding-top: 20px;
      font-size: 4rem;
    }
    @media (max-height: 500px),
    screen and (max-width: 500px) {
      padding-top: 30px;
      font-size: 2rem;
    }
  }
  & .timer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    height: auto;
    padding-top: 10px;

    @media screen and (max-height: 700px),
    screen and (max-width: 700px) {
      font-size: 3rem;
    }
    @media (max-height: 600px),
    screen and (max-width: 600px) {
      padding-top: 20px;
      font-size: 2rem;
    }
    @media (max-height: 500px),
    screen and (max-width: 500px) {
      padding-top: 30px;
      font-size: 2rem;
    }
  }

  & .time-attack-finish {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2%;
  }
}

.scores-container {
  position: relative;
  padding: 45px 15px 15px;
  background: white;
  color: #222222;
  font-size: 1.7rem;

  table {
    margin: auto;
    border-spacing: 0px 10px;
  }

  td {
    width: 25%;
    padding: 10px 0;
    border-bottom: 1px solid white;
  }

  & .score-value {
    display: flex;
    align-items: center;
    justify-content: right;
    float: right;
  }
}

.scores-container::before,
.scores-container::after {
  content: ' ';
  top: 0;
  left: 0;
  position: absolute;
  width : 100%;
  border-left: 100vw solid $background-color-first;
  border-bottom: 35px solid transparent;
  color: white;
}

.scores-container::after {
  top: 100%;
  border-left: 100vw solid white;
}

@import '../../../../resources/styles/layout/variables';

.workout-frame {
  position: relative;
  height: 100vh;
  width: 100%;
  color: white;
  background-color: $background-exercise-color;
  overflow: hidden;

  &.warmup-time {
    background-color: $background-warmup-color;
  }
  &.frac-resting-time {
    background-color: $background-rest-color;
  }
  &.series-resting-time {
    background-color: $background-big-rest-color;
  }

  & button.btn,
  & a.btn {
    border: 5px solid white;
    color: white;
    border-radius: 50px;
    background: none;
    padding: 25px 30px;
    line-height: 40px;
    font-size: 2rem;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    & > i {
      padding-right: 20px;
      font-size: 2.5rem;
    }
    @media screen and (max-width: 700px) {
      font-size: 1.5rem;
      & > i {
        font-size: 2rem;
      }
    }
  }

  & a.btn {
    text-decoration: none;
  }
}

.retour-home-container,
.ajout-favoris-container {
  position: absolute;
  z-index: 100;
  top: 20px;
  left: 30px;
  color: white;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  padding: 1px 6px;

  & > i {
    font-size: 3rem;
  }
}

.retour-home-container {
  & > i {
    @media (max-height: 700px),
    screen and (max-width: 700px) {
      font-size: 2.5rem;
    }
    @media (max-height: 500px),
    screen and (max-width: 500px) {
      font-size: 2rem;
    }
  }
}

.ajout-favoris-container {
  right: 20px;
  top: 32px;
  left: auto;
  font-size: 1.5rem;
  padding: 1px 6px;

  & > i {
    font-size: 1.5rem;
    padding-right: 15px;
  }
  @media (max-height: 700px),
  screen and (max-width: 700px) {
    font-size: 1.2rem;
    & > i {
      font-size: 1.2rem;
      padding-right: 5px;
    }
  }
}


.series-number {
  position: absolute;
  display: flex;
  width: 100%;
  bottom: 0;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0.5rem;
  font-size: 1.5rem;
}

.flip-h {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
}

.warmup-time {
  & .counter-container {
    bottom: 170px;
    font-size: 4rem;
    top: auto;
    height: auto;
  }
}

.counter-container {
  position: absolute;
  top: 40vh;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 14rem;
  font-weight: 700;
  user-select:none;

  &.count-down-animation {
    animation: 1s ease-out 0s infinite zoom-in;
  }

  @media screen and (max-height: 700px),
  screen and (max-width: 700px) {
    font-size: 12rem;
  }
  @media (max-height: 600px),
  screen and (max-width: 600px) {
    font-size: 10rem;
  }
  @media (max-height: 500px),
  screen and (max-width: 500px) {
    font-size: 8rem;
  }
}

@keyframes zoom-in {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 700px) {
  .counter-container {
    top: 35vh;
    height: 40vh;
  }
}


.modal {
  position: relative;
  width: 800px;

  @media (max-height: 1024px),
  screen and (max-width: 1024px) {
    width: 90%;
  }

  & .delete-exercise-container {
    position: absolute;
    top: -15px;
    right: -10px;

    & button {
      cursor: pointer;
      border: 2px solid grey;
      color: white;
      border-radius: 50px;
      background: grey;
      font-size: 1.3rem;
      font-weight: 600;
      text-align: center;
    }
    button:focus  {
      outline: none;
      border: 2px solid #000000;
    }
  }

  & h1 {
    font-size: 2rem;
  }
}

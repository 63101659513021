body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  color: $text-color;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

h1, h2, h3 {
  color: $text-color;
}

h1 {
  font-size: 5rem;
}

h2 {
  font-size: 2.5rem;
  @media screen and (max-width: 800px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 1.7rem;
  }
}

h3 {
  font-size: 1.3rem;
}

p {
  text-align: justify;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: .03rem;
  @media screen and (max-width: 800px) {
    font-size: 1rem;
    letter-spacing: .01rem;
    line-height: 1.7rem;
  }
}

a {
  color: $primary-color;
  text-decoration: none;
}

.timer-logo-small {
  width: 64px;
  padding: 0px 32px;

  @media screen and (max-height: 700px),
  screen and (max-width: 700px) {
    width: 50px;
    padding-right: 18px;
  }
  @media (max-height: 600px),
  screen and (max-width: 600px) {
    width: 32px;
    padding-right: 12px;
  }
  @media (max-height: 500px),
  screen and (max-width: 500px) {
    width: 32px;
    padding-right: 18px;
  }
}

.timer-logo-xsmall {
  width: 48px;
  padding-right: 24px;

  @media screen and (max-height: 700px),
  screen and (max-width: 700px) {
    width: 32px;
    padding-right: 18px;
  }
  @media (max-height: 600px),
  screen and (max-width: 600px) {
    width: 24px;
    padding-right: 18px;
  }
  @media (max-height: 500px),
  screen and (max-width: 500px) {
    width: 32px;
    padding-right: 18px;
  }
}
